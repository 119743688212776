<template>
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <PageHeader
        :showButton="false"
        :heading="$t('xpbx.settings.ring_groups.heading')"
      />

      <!-- Queues table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="ringGroups"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          v-if="!loading"
          :paginator="true"
          :rows="10"
          removableSort
          v-model:selection="selectedRecords"
          v-model:filters="filters"
          paginatorPosition="top"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} ring groups"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          dataKey="id"
          selectionMode="single"
          :globalFilterFields="['name']"
        >
          <template #empty>
            {{ $t("xpbx.settings.fields.empty_table") }}</template
          >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  :label="$t('xpbx.button.new')"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
              </div>
              <div class="flex gap-2 items-center ml-auto">
                <InputText
                  class="search-input"
                  v-model="filters['global'].value"
                  :placeholder="$t('xpbx.placeholders.search')"
                />
              </div>
            </div>
          </template>
          <Column
            sortable
            field="name"
            style="width: 25%"
            :header="$t('xpbx.settings.ring_groups.fields.name')"
          >
            <template #body="{ data }">
              {{ data?.name }}
              <div>
                <TableActions
                  :data="data"
                  :id="data.id"
                  @setActive="setActive"
                  :showEdit="false"
                  :link="`/xpbx/settings/ring-group/${data?.id}`"
                  :isActive="data.is_active"
                  @delete="deleteSelectedRecord"
                />
              </div>
            </template>
          </Column>
          <Column
            sortable
            field="ring_time"
            style="width: 25%"
            :header="$t('xpbx.settings.ring_groups.fields.ring_time')"
          >
            <template #body="{ data }">
              <div class="my-2">{{ data?.ring_time }}</div>
            </template>
          </Column>
          <Column
            sortable
            field="ring_strategy"
            style="width: 25%"
            :header="$t('xpbx.settings.ring_groups.fields.ring_strategy')"
          >
            <template #body="{ data }">
              {{ getStrategy(data?.ring_strategy) }}
            </template>
          </Column>
          <Column
            sortable
            field="music_on_hold"
            style="width: 25%"
            :header="$t('xpbx.settings.ring_groups.fields.music_on_hold')"
          >
            <template #body="{ data }">
              <div class="my-2">{{ getMusicTypa(data?.music_on_hold) }}</div>
            </template>
          </Column>
        </DataTable>
        <Loader v-else />
      </div>

      <!-- DIalogs -->
      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="selectedRecord?.name">
            {{
              $t("xpbx.settings.ring_groups.notification.confirm_delete", {
                delete: `ring group ${selectedRecord?.name}`,
              })
            }}</span
          >
        </div>
        <template #footer>
          <Button
            text
            :label="$t('xpbx.button.no')"
            @click="deleteRecordsDialog = false"
          />
          <Button
            text
            :label="$t('xpbx.button.yes')"
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create template -->
      <Dialog
        v-model:visible="ringGroupDialog"
        :style="{ width: '700px' }"
        :header="$t('xpbx.settings.ring_groups.dilogs.create_ring_group')"
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <!--Name -->
        <FormInput
          id="name"
          :error="errors?.name?.[0]"
          v-model:modelValue="form.name"
          :label="$t('xpbx.settings.ring_groups.labels.name')"
          :hint="$t('xpbx.settings.ring_groups.hints.name')"
        />

        <!--Strategy -->
        <FormDropdown
          optionLabel="name"
          :options="strategyOptions"
          :error="errors?.ring_strategy?.[0]"
          v-model:modelValue="form.ring_strategy"
          :label="$t('xpbx.settings.ring_groups.labels.strategy')"
          :hint="$t('xpbx.settings.ring_groups.hints.strategy')"
        />

        <!--Ring time -->
        <FormDropdown
          :options="periodOptions"
          :error="errors?.ring_time?.[0]"
          v-model:modelValue="form.ring_time"
          :label="$t('xpbx.settings.ring_groups.labels.ring_time')"
          :hint="$t('xpbx.settings.ring_groups.hints.ring_time')"
        />

        <!--Agent period -->
        <FormDropdown
          optionLabel="name"
          :options="muscicOnHoldOptions"
          :error="errors?.music_on_hold?.[0]"
          v-model:modelValue="form.music_on_hold"
          :label="$t('xpbx.settings.ring_groups.labels.music_on_hold')"
          :hint="$t('xpbx.settings.ring_groups.hints.music_on_hold')"
        />

        <!--Is active -->
        <InputSwitch
          v-model:modelValue="form.is_active"
          :label="$t('xpbx.settings.ring_groups.labels.is_active')"
        />

        <template #footer>
          <DialogButtons
            :saveButtonText="$t('xpbx.button.save')"
            :cancelButtonText="$t('xpbx.button.cancel')"
            @save="create"
            @cancel="ringGroupDialog = false"
          />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, inject } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import useRingGroups from "@/modules/xpbx/composables/useRingGroups";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/DateItem/DateItem.vue";
import { validateCreateRingGroup } from "@/composables/auth/createDomainValidations";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Tooltip from "primevue/tooltip";
import DataTable from "primevue/datatable";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "RingGroups",

  components: {
    PageHeader,
    DateItem,
    DataTable,
    Column,
    Loader,
    Button,
    InputText,
    Dialog,
    FormInput,
    FormDropdown,
    InputSwitch,
    TableActions,
    DialogButtons,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const t = inject("t");
    const selectText = t("xpbx.settings.dropdown.select_option");
    const defaultOption = ref({ name: selectText, value: null });
    // Datatable
    const dt = ref();
    const isEdit = ref(false);
    const submitted = ref(false);
    const form = ref({
      name: "",
      ring_strategy: null,
      ring_time: "0",
      strategy: null,
      is_active: false,
    });
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const ringGroupDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const {
      periodOptions,
      strategyOptions,
      muscicOnHoldOptions,
      getOptionValue,
    } = useQueueOptions();
    const {
      loading,
      ringGroups,
      findRingGroups,
      updateRingGroup,
      createRingGroup,
      deleteRingGroup,
    } = useRingGroups();

    // Data table functions
    const openNew = () => {
      form.value = {
        name: "",
        ring_time: "0",
        is_active: false,
        ring_strategy: defaultOption.value,
        music_on_hold: defaultOption.value,
      };
      clearErrors();
      isEdit.value = false;
      ringGroupDialog.value = true;
    };

    const closeDialog = (value) => {
      ringGroupDialog.value = value;
    };

    const reset = () => {
      form.value = {
        name: "",
        ring_time: "0",
        is_active: false,
        ring_strategy: defaultOption.value,
        music_on_hold: defaultOption.value,
      };
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const createRecord = async (record) => {
      const isValid = validateCreateRingGroup(record);

      if (isValid) {
        const formData = {
          ...record,
          is_active: record.is_active ? 1 : 0,
          ring_time: getOptionValue(record.ring_time),
          ring_strategy: getOptionValue(record.ring_strategy),
          music_on_hold: getOptionValue(record.music_on_hold),
        };

        await createRingGroup(formData);

        isEdit.value = false;
        ringGroupDialog.value = false;
      }
    };

    const updateRecord = async (record) => {
      if (record.id) {
        const formData = {
          ...record,
          is_active: record.is_active ? 1 : 0,
          ring_time: getOptionValue(record.ring_time),
          ring_strategy: getOptionValue(record.ring_strategy),
          music_on_hold: getOptionValue(record.music_on_hold),
        };

        await updateRingGroup(formData, record.id);

        isEdit.value = false;
        ringGroupDialog.value = false;
      }
    };

    const create = async () => {
      submitted.value = true;
      if (isEdit.value) {
        await updateRecord(form.value);
      } else {
        await createRecord(form.value);
      }
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteRingGroup(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const getStrategy = (value) => {
      if (!value) return "";
      const strategy = t(`xpbx.settings.ring_groups.startegy.${value}`);
      return strategy && strategy !== "" ? strategy : value;
    };

    const getMusicTypa = (value) => {
      if (!value) return "";
      const strategy = t(`xpbx.settings.ring_groups.music_type.${value}`);
      return strategy && strategy !== "" ? strategy : value;
    };

    const setActive = async (data) => {
      const formData = {
        ...data,
        is_active: data.is_active === 1 ? 0 : 1,
      };

      await updateRingGroup(formData, data.id);
    };

    onMounted(async () => {
      await findRingGroups();
    });

    return {
      // Data
      dt,
      isEdit,
      form,
      loading,
      errors,
      submitted,
      filters,
      selectText,
      ringGroups,
      periodOptions,
      strategyOptions,
      ringGroupDialog,
      scrollHeight,
      defaultOption,
      selectedRecord,
      selectedRecords,
      deleteRecordsDialog,
      muscicOnHoldOptions,
      // Methods
      reset,
      create,
      openNew,
      setActive,
      getStrategy,
      closeDialog,
      createRecord,
      updateRecord,
      clearErrors,
      getMusicTypa,
      findRingGroups,
      updateRingGroup,
      createRingGroup,
      deleteRingGroup,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },

  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.regex-example {
  color: #10b981;
  margin-top: 4px;
  display: inline-block;
}

.edit-icon {
  color: #10b981;
}
</style>